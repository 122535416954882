import color from '@/assets/sass/color.scss'

const stripe = new window.Stripe(process.env.VUE_APP_STRIPE_ID)
const elements = stripe.elements({
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap'
    }
  ]
})

// Elementsのスタイルを指定
const elementStyles = {
  base: {
    'color': color.black_color,
    'letterSpacing': '1px',
    'fontSize': '16px',
    'fontWeight': '400',
    'lineHeight': '22px',
    'fontFamily': '"Noto Sans JP", Avenir, Helvetica, Arial, sans-serif',
    '::placeholder': {
      'color': color.gray_color
    }
  }
}

const elementClasses = {
  focus: 'focus',
  empty: 'empty',
  invalid: 'invalid'
}

// 対象の入力エレメントのオブジェクトを作成
const cardNumber = elements.create('cardNumber', {
  style: elementStyles,
  classes: elementClasses,
  placeholder: '1234　5678　9101 1121'
})

const cardExpiry = elements.create('cardExpiry', {
  style: elementStyles,
  classes: elementClasses,
  placeholder: 'MM/YY'
})

const cardCvc = elements.create('cardCvc', {
  style: elementStyles,
  classes: elementClasses,
  placeholder: 'CVC'
})

export { stripe, cardNumber, cardExpiry, cardCvc }
