<template>
  <div class="card">
    <p class="card__title">カード情報<span class="card__title__required">*</span></p>
    <div class="card__info">
      <div id="card-number" class="card__info__stripe--number" />
      <div id="card-expiry" class="card__info__stripe--expiry" />
      <div id="card-cvc" class="card__info__stripe--cvc" />
    </div>
    <p class="card__title">カード名義<span class="card__title__required">*</span>
      <span class="card__title__error" v-if="isInvalidCardName">アルファベットと半角空白のみ入力できます</span></p>
    <input class="card__name" :class="{'card__name--error': isInvalidCardName}"
           placeholder="TARO TANAKA" v-model="holderName" type="text"
           @input="emitCardName" />
  </div>
</template>

<script>
import { cardNumber, cardExpiry, cardCvc } from '@/stripe'

export default {
  props: {
    /**
     * カード名義のバリデーション結果
     */
    isValidCardName: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      // カード名義
      holderName: ''
    }
  },
  mounted () {
    // 親コンポーネントへカード情報を渡す
    this.$nextTick(() => {
      // カード番号
      cardNumber.mount('#card-number')
      cardNumber.addEventListener('change', event => {
        this.$emit('set-card', 'cardNumber', cardNumber, event.complete)
        if (event.complete) {
          cardExpiry.focus()
        }
      })
      // カード期限
      cardExpiry.mount('#card-expiry')
      cardExpiry.addEventListener('change', event => {
        this.$emit('set-card', 'cardExpiry', cardExpiry, event.complete)
        if (event.complete) {
          cardCvc.focus()
        }
      })
      // カードCVC
      cardCvc.mount('#card-cvc')
      cardCvc.addEventListener('change', event => {
        this.$emit('set-card', 'cardCvc', cardCvc, event.complete)
      })
    })
  },
  computed: {
    /**
     * カード名義に入力された文字列がバリデーションに引っかかているかを判定する
     *
     * @return {Boolean} 引っかかっている(true) or 引っかかっていない(false)
     */
    isInvalidCardName () {
      return !(this.isValidCardName || this.holderName === '')
    }
  },
  methods: {
    /**
     * 親コンポーネントへカード名を渡す
     */
    emitCardName () {
      this.$emit('set-card-name', 'cardName', this.holderName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.card {
  box-sizing: border-box;
  margin: 0 auto;
  &__title {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
    &__required {
      color: $red_color;
    }
    &__error {
      margin: 0;
      margin-left: $unit_size;
      font-size: 1rem;
      font-weight: normal;
      color: $red_color;
    }
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100px;
    margin: 10px 0 30px;
    background-color: $white_color;
    border: 1px solid $gray_color;
    border-radius: 15px;
    &__stripe {
      height: 50%;
      padding: 14px;
      &--number {
        @extend .card__info__stripe;
        width: 100%;
        border-bottom: 1px solid $gray_color;
      }
      &--expiry {
        @extend .card__info__stripe;
        flex: 1;
      }
      &--cvc {
        @extend .card__info__stripe;
        flex: 1;
        border-left: 1px solid $gray_color;
      }
    }
  }
  &__name {
    width: 100%;
    height: 50px;
    padding: 0 14px;
    margin-top: 10px;
    font-size: 1.6rem;
    line-height: 2.2rem;
    background-color: $white_color;
    border: 1px solid $gray_color;
    border-radius: 15px;
    outline: 0;
    &::placeholder {
      color: $gray_color;
    }
    &--error {
      @extend .card__name;
      color: $red_color;
    }
  }
}
</style>
