<template>
  <div :class="isPaymentError ? 'card--error' : 'card'">
    <v-icon class="card__icon" v-if="isPaymentError">error</v-icon>
    <h2 :class="isPaymentError ? 'card__title--error' : 'card__title'">現在使用中のカード</h2>
    <p class="card__text">
      {{ brand }}
      <span class="card__text__number">****{{ last4 }}</span>
    </p>
    <p class="card__text">{{ name }}</p>
    <p class="card__text--exp">有効期限は{{ expMonth }}/{{ expYear }}です。</p>
  </div>
</template>

<script>
export default {
  computed: {
    /**
     * @return {Object} サブスクリプション情報
     */
    subscription () {
      return this.$store.getters['subscriptions/subscription']
    },
    /**
     * @return {Object} カード情報
     */
    card () {
      return this.$store.getters['cards/card']
    },
    /**
     * @return {String} カード名義
     */
    name () {
      return this.card ? this.card.name : null
    },
    /**
     * @return {String} カードのブランド
     */
    brand () {
      return this.card ? this.card.brand : null
    },
    /**
     * @return {String} カード番号下4桁
     */
    last4 () {
      return this.card ? this.card.last4 : null
    },
    /**
     * @return {String} 2桁に整形した有効期限の月
     */
    expMonth () {
      return this.card ? ('00' + this.card.expMonth).slice(-2) : null
    },
    /**
     * @return {String} 2桁に整形した有効期限の年
     */
    expYear () {
      return this.card ? ('00' + this.card.expYear).slice(-4) : null
    },
    /**
     * @return {Boolean} カード支払い失敗中かどうか
     */
    isPaymentError () {
      const payment = this.$store.getters['payments/payment']
      return (payment && payment.status === 'cardError') || (this.subscription && ['incomplete', 'incomplete_expired', 'past_due', 'unpaid'].includes(this.subscription.status))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";

.card {
  position: relative;
  box-sizing: border-box;
  min-height: 115px;
  padding: 20px 15px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  border: 1px solid $gray_lighten_color;
  border-radius: 15px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
  &--error {
    @extend .card;
    border: 1px solid $red_color;
  }
  &__icon {
    position: absolute;
    top: 20px;
    right: 15px;
    width: 20px;
    height: 20px;
    &.v-icon {
      font-size: 2rem;
      color: $red_color;
    }
  }
  &__title {
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.5rem;
    color: $concept_color;
    &--error {
      @extend .card__title;
      color: $red_color;
    }
  }
  &__text {
    margin: 5px 0 0;
    &__number {
      margin-left: 10px;
    }
    &--exp {
      @extend .card__text;
      color: $gray_color;
    }
  }
}
</style>
